body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url(../public/Assets/Fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url(../public/Assets/Fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url(../public/Assets/Fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url(../public/Assets/Fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url(../public/Assets/Fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url(../public/Assets/Fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url(../public/Assets/Fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url(../public/Assets/Fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: url(../public/Assets/Fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}
